<template>
    <div class="layout-task">

        <div class="layout-task-menu">
            <div class="menu-system">
                <div class="menu-top">
                    <h1 class="title" :class="taskInspectionTypeId === ''?'active':''" @click="onLinks('')">作业任务分类</h1>
                    <a href="javascript:" @click="onAddTaskClass"><Icon custom="circle"/></a>
                </div>
<!--                <div class="menu-input">-->
<!--                    <Input suffix="ios-search" placeholder="请输入" class="input"/>-->
<!--                </div>-->
            </div>
            <div class="menu-list">
                <div class="list-item" :key="'child1'+key" v-for="(deviceList,key) in deviceListArray">
                    <div class="item-thead" :style="deviceList.children.length === 0?'padding-left:24px':''">
                        <a href="javascript:">
                            <Icon v-if="deviceList.children.length!==0" :type="deviceList.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon icon-arrow"  @click="deviceList.visible = !deviceList.visible"/>
                            <Icon :custom="deviceList.visible && deviceList.children.length!==0?'menu-open-folder':'menu-folder'"></Icon>
                            <p :class="taskInspectionTypeId ===  deviceList.value?'active':''" @click="onLinks(deviceList.value)">{{ deviceList.label }}</p>
                        </a>
                        <div class="child-system">
                            <Dropdown :transfer="true">
                                <a href="javascript:">
                                    <Icon custom="more"/>
                                </a>
                                <DropdownMenu slot="list" class="system-menu">
                                    <DropdownItem>
                                        <a href="javascript:" @click="onUpdateType(deviceList)">编辑</a>
                                    </DropdownItem>
                                    <DropdownItem v-if="key !== 0">
                                        <a href="javascript:" @click="onSortableTask(deviceList.value,1)">上移</a>
                                    </DropdownItem>
                                    <DropdownItem v-if="key+1 !== deviceListArray.length">
                                        <a href="javascript:" @click="onSortableTask(deviceList.value,2)">下移</a>
                                    </DropdownItem>
                                    <DropdownItem v-if="key !== 0">
                                        <a href="javascript:" @click="onSortableTask(deviceList.value,3)">置顶</a>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <a href="javascript:" @click="onDelTask(deviceList.value)">删除</a>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <a href="javascript:" class="href" @click="onChildAddTaskClass(deviceList.value)"><Icon custom="circle" class="icon"/></a>
                        </div>
                    </div>
                    <div class="item-body" v-if="deviceList.visible">
                        <div class="item-child" :key="'child2'+key" v-for="(children,key) in deviceList.children">
                            <div class="child-thead" :style="children.children.length === 0?'padding-left:45px':''">
                                <a href="javascript:" class="href">
                                    <Icon v-if="children.children.length !== 0"  @click="children.visible = !children.visible" :type="children.visible?'md-arrow-dropdown':'md-arrow-dropright'" class="icon icon-arrow" />
                                    <Icon :custom="children.visible && children.children.length !== 0?'menu-open-folder':'menu-folder'"></Icon>
                                    <p :class="taskInspectionTypeId === children.value?'active':''" @click="onLinks(children.value)">{{ children.label }}</p>
                                </a>
                                <div class="child-system">
                                    <Dropdown :transfer="true">
                                        <a href="javascript:">
                                            <Icon custom="more"/>
                                        </a>
                                        <DropdownMenu slot="list" class="system-menu">
                                            <DropdownItem>
                                                <a href="javascript:" @click="onUpgradeTask(children.value)">升级</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a href="javascript:" @click="onUpdateType(children)">编辑</a>
                                            </DropdownItem>
                                            <DropdownItem v-if="key !== 0">
                                                <a href="javascript:" @click="onSortableTask(children.value,1)">上移</a>
                                            </DropdownItem>
                                            <DropdownItem v-if="key+1 !== deviceList.children.length">
                                                <a href="javascript:" @click="onSortableTask(children.value,2)">下移</a>
                                            </DropdownItem>
                                            <DropdownItem v-if="key !== 0">
                                                <a href="javascript:" @click="onSortableTask(children.value,3)">置顶</a>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <a href="javascript:" @click="onDelTask(children.value)">删除</a>
                                            </DropdownItem>
                                        </DropdownMenu>
                                  </Dropdown>
                                  <a href="javascript:" class="href" @click="onChildAddTaskClass(children.value)"><Icon custom="circle" class="icon"/></a>
                              </div>
                            </div>
                            <div class="child-body" v-if="children.visible">
                                <ul>
                                    <li :key="'child3'+key" v-for="(child,key) in children.children">
                                        <a href="javascript:" class="href" :class="taskInspectionTypeId === child.value?'active':''" @click="onLinks(child.value)">
                                          <Icon custom="menu-folder"></Icon>
                                          {{ child.label }}
                                        </a>
                                        <div class="child-system">
                                            <Dropdown :transfer="true">
                                                <a href="javascript:">
                                                    <Icon custom="more"/>
                                                </a>
                                                <DropdownMenu slot="list" class="system-menu">
                                                    <DropdownItem>
                                                        <a href="javascript:" @click="onUpgradeTask(child.value)">升级</a>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <a href="javascript:" @click="onUpdateType(child)">编辑</a>
                                                    </DropdownItem>
                                                    <DropdownItem v-if="key !== 0">
                                                        <a href="javascript:" @click="onSortableTask(child.value,1)">上移</a>
                                                    </DropdownItem>
                                                    <DropdownItem v-if="key+1 !== children.children.length">
                                                        <a href="javascript:" @click="onSortableTask(child.value,2)">下移</a>
                                                    </DropdownItem>
                                                    <DropdownItem v-if="key !== 0">
                                                        <a href="javascript:" @click="onSortableTask(child.value,3)">置顶</a>
                                                    </DropdownItem>
                                                    <DropdownItem>
                                                        <a href="javascript:" @click="onDelTask(child.value)">删除</a>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </Dropdown>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="layout-task-list" :style="menuVisible?'width: calc(100% - 560px);':'width: calc(100% - 310px);'">
            <!--s: Search 区域-->
            <div class="device-list-search">
                <div class="device-list-select">
                    <div class="box">
                        <div class="box-list">
                            <span class="label">任务名称：</span>
                            <Input v-model="taskListParams.tasksName" clearable placeholder="任务名称" style="width: 100px" />
                        </div>
<!--                        <div class="box-list" style="width:190px">-->
<!--                            <span class="label">任务类型：</span>-->
<!--                            <Select v-model="taskListParams.tasksType" clearable style="width: 100px">-->
<!--                                <Option v-for="(type,key) in tasksType" :value="type.value" :key="key">{{ type.label }}</Option>-->
<!--                            </Select>-->
<!--                        </div>-->
                        <div class="box-list" style="width:190px">
                            <span class="label">任务状态：</span>
                            <Select v-model="taskListParams.status" clearable style="width: 100px">
                                <Option v-for="(status,key) in taskStatus" :value="status.value" :key="key">{{ status.label }}</Option>
                            </Select>
                        </div>
                        <div class="box-list">
                            <span class="label">引用流程：</span>
                            <Input v-model="taskListParams.flowName" clearable placeholder="引用流程" style="width: 100px" />
                        </div>
                    </div>
                    <div class="box">
                        <Button @click="onReset">重置</Button>
                        <Button type="primary" @click="onSearch">查询</Button>
                      <Button type="primary" icon="ios-add" @click="onAddList">新增</Button>
                    </div>
                </div>
<!--                <div class="device-list-select right">-->
<!--                    <Button type="primary" icon="ios-add" @click="onAddList">新增</Button>-->
<!--                </div>-->
            </div>
            <!--e: Search 区域-->
            <!--s: Data 区域-->
            <div class="device-list task-list">
                <Table :loading="taskLoading" :height="deviceListHeight" stripe="stripe" :columns="taskListColumns" :data="taskListArray.content" >
                    <template slot="任务名称" slot-scope="{ row }">
                        <Poptip placement="top-start" trigger="hover" :content="row.tasksName">
                            <span class="task-name">{{ row.tasksName }}</span>
                        </Poptip>
                    </template>
                    <template slot="引用流程" slot-scope="{ row }">
                        <Poptip placement="top-start" trigger="hover" :content="row.flowName">
                            <a href="javascript:" class="task-flow" @click="onFlowConfig(row)">{{ row.flowName }}</a>
                        </Poptip>
                    </template>
                    <template slot="发布人" slot-scope="{ row }">
                        <span class="create-name">{{ row.createName }}</span>
                    </template>
                    <template slot="审批人" slot-scope="{ row }">
                        <Poptip placement="bottom-start">
                            <Button class="user-item" v-if="row.approvalUsers.length!==0">{{ row.approvalUsers.length === 1?row.approvalUsers[0].userName:row.approvalUsers[0].userName+'...' }}</Button>
                            <template #content v-if="row.approvalUsers.length!==0">
                                <h1 class="approve-title">依此审批 <span class="label" v-if="row.approvalUsers.filter(item=>item.state === 1).length === row.approvalUsers.length">已通过</span></h1>
                                <div class="approval-box">
                                    <div class="approve-steps-item" :key="key" v-for="(approval,key) in row.approvalUsers">
                                        <div class="steps-tail" :class="approval.state === 0?'green':approval.state === 2?'red':''" v-if="key!==row.approvalUsers.length-1"></div>
                                        <div class="steps-head" :class="approval.state === 0?'green':approval.state === 2?'red':''"><Icon :type="approval.state === 0?'ios-radio-button-off':approval.state === 1?'ios-checkmark-circle':'ios-close-circle'" size="20"/></div>
                                        <div class="steps-main">
                                            <div class="steps-title">{{ key+1 }}级审批人{{ approval.state === 1?'/已同意':approval.state === 2?'/已驳回':'' }}</div>
                                            <div class="steps-content">
                                                <a href="javascript:" class="steps-add"  v-if="approval.id === ''"><Icon type="md-add" size="20"/></a>
                                                <div class="steps-name-box" v-if="approval.id !== ''">
                                                    <span class="avatar" :style="'background-image:url('+(approval.profilePicture === ''?defaultAvatar:approval.profilePicture)+')'"></span>
                                                    <div class="text">
                                                        <span class="name">{{ approval.userName }}</span>
                                                        <span class="tel">{{ approval.mobile }}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Poptip>
                        {{ row.approvalUsers.length === 0?'/':'' }}
                    </template>
                    <template slot="状态" slot-scope="{ row }">
                        <font v-if="row.status === 0" class="black">未发布</font>
                        <font v-if="row.status === 1" class="blue">可执行</font>
                        <font v-if="row.status === 2" class="red">待审批</font>
                        <font v-if="row.status === 3" class="yellow">进行中</font>
                        <font v-if="row.status === 4" class="black">已退回</font>
                        <font v-if="row.status === 5" class="grey">已关闭</font>
                        <font v-if="row.status === 6" class="green">已完成</font>
                        <font v-if="row.status === 7" class="light-red">已超时</font>
                    </template>
                    <template slot="执行人" slot-scope="{ row }">
                        <Poptip placement="bottom-start">
                            <Button class="user-item" v-if="row.executorUsers.length!==0">{{ row.executorUsers.length === 1?row.executorUsers[0].userName:row.executorUsers[0].userName+'...' }}</Button>
                            <template #content v-if="row.executorUsers.length!==0">
                                <div class="task-box">
                                    <div class="user-item-box" :key="key" v-for="(userList,key) in row.executorUsers">
                                        <span class="avatar" :style="'background-image:url('+(userList.profilePicture === ''?defaultAvatar:userList.profilePicture)+')'"></span>
                                        <div class="box-r">
                                            <span class="name">{{ userList.userName }}</span>
                                            <span class="tel">{{ userList.mobile }}</span>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Poptip>
                        {{ row.executorUsers.length === 0?'/':'' }}
                    </template>
                    <template slot="创建时间" slot-scope="{ row }">
                        {{ row.createTime?row.createTime.replace(/:..$/, '') : '/'}}
                    </template>
                    <template slot="操作" slot-scope="{ row }">
                        <div class="handle">
                            <a href="javascript:" v-if="row.status === 1 || row.status === 2 || row.status === 3 || row.status === 4 || row.status === 6 || row.status === 7 " @click="onUpdateStatusCheck(row,0)">撤回</a>
                            <a href="javascript:" class="blue" v-if="row.status === 0" @click="onUpdateStatusCheck(row,1)">发布</a>
                            <a href="javascript:" class="blue" v-if="row.status === 0" @click="onEditList(row)">编辑</a>
                            <a href="javascript:" v-if="row.status === 0 || row.status === 5" @click="onDelete(row.id)">删除</a>
                            <a href="javascript:" v-if="row.status === 1 || row.status === 2 || row.status === 3 || row.status === 4 || row.status === 6 || row.status === 7" @click="onCode(row)">二维码</a>
                        </div>
                    </template>
                </Table>
                <div class="device-list-page" v-if="taskListArray.content && taskListArray.content.length !== 0">
                    <Page :total="taskListArray.totalSize" :current="taskListArray.currentPage" show-total show-sizer size="small" :page-size="taskListParams.displayNumber" @on-change="onSearchPage" @on-page-size-change="onSearchPageSize"/>
                </div>
            </div>
            <!--e: Data 区域-->

            <Modal
                v-model="taskModalVisible"
                :title="btnStatus?'新增':'编辑'"
                footer-hide>
                <div class="task-modal">
                    <span class="label">任务分类：</span>
                    <Cascader v-model="taskEditParams.classificationId" style="width: 300px" change-on-select :data="deviceListArray" ></Cascader>
                </div>
                <div class="task-modal">
                    <span class="label">任务名称：</span>
                    <Input v-model="taskEditParams.tasksName" maxlength="20" show-word-limit placeholder="请输入" style="width: 300px" />
                </div>
<!--                <div class="task-modal modal-print">-->
<!--                    <span class="label">任务类型：</span>-->
<!--                    <Select v-model="taskEditParams.tasksType" :disabled="tasksTypeDisabled" clearable style="width: 300px">-->
<!--                        <Option v-for="item in tasksType" :value="item.value" :key="item.id">{{ item.label }}</Option>-->
<!--                    </Select>-->
<!--                    <div class="print">{{ btnStatus?'仔细确认任务类型，创建后不可更改。':'任务已创建，不可更改。' }}</div>-->
<!--                </div>-->
                <div class="task-modal">
                    <span class="label">引用流程：</span>
                    <Select v-model="taskEditParams.flowId" clearable style="width: 300px">
                        <Option v-for="item in workflowListArray" :value="item.id" :key="item.id">{{ item.flowName }}</Option>
                    </Select>
                </div>
                <div class="task-modal-switch">
                    设置每次执行任务均从头开始
                    <div class="btn-switch">
                        <onSwitch v-model="taskEditParams.anew" :true-value="1" :false-value="0" size="small"></onSwitch>
                    </div>
                </div>
                <div class="task-footer task-btn">
                    <Button type="primary" :loading="editLoading" @click="onSubmitCheck(1)">立即发布</Button>
                    <Button type="primary" :loading="editLoading" ghost @click="onSubmitCheck(0)">稍后发布</Button>
                    <Button @click="taskModalVisible = false">取 消</Button>
                </div>
            </Modal>

            <!--s: Code modal-->
            <Modal
                v-model="qrCodeVisible"
                title="二维码"
                :width="600"
                class-name="task-modal"
                footer-hide>
                <div class="task-code">
                    <div class="box">
                        <div class="code" id="qrcode" ref="taskRef">
                            <vue-qr :logoSrc="logoSrc" :text="QRCode.toString()" :size="500" class="layout-qr-code"></vue-qr>
                            <span class="name">{{ tasksName }}</span>
                        </div>
                        <div class="down-load">
                            <a href="javascript:" @click="onDowns"><Icon custom="image upload" />下载任务二维码</a>
                        </div>
                    </div>
                </div>
            </Modal>
            <!--e: Code modal-->
        </div>

        <Modal
            v-model="taskModalClassVisible"
            :title="btnStatus?'添加任务分类':'修改任务分类'"
            footer-hide>
            <div class="device-modal">
                <span class="label">名称：</span>
                <Input v-model="taskClassName" maxlength="10" show-word-limit placeholder="请输入" style="width: 300px" />
            </div>
            <div class="device-footer">
                <Button @click="taskModalClassVisible = false">取 消</Button>
                <Button type="primary" :loading="taskClassLoading" @click="onAddTaskClassCheck">确 定</Button>
            </div>
        </Modal>

    </div>
</template>
<script>
import TaskList from './task'
export default TaskList
</script>
<style lang="less">
@import "task";
</style>
